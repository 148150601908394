html,
body {
  margin: 0;
  padding: 0;
  max-height: 100%;
  overflow-y: hidden;
}
.hidden {
  display: none !important;
}
.home-container {
  max-height: 100vh;
  width: 100%;
  background: black;

  img,
  video {
    height: 100vh;
    object-fit: contain;
  }
}